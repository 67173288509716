<template>
  <div>
    <loading-bar :value="loading"></loading-bar>
    <v-expand-transition>
      <v-card v-if="profiles.length">
        <v-toolbar color="color2 color2Text--text">
          <v-toolbar-title>Is This You?</v-toolbar-title>
        </v-toolbar>
        <v-window v-model="window">
          <v-window-item :key="0">
            <v-card-title>
              We have found {{someText}} your contact info. Please review them for us.
            </v-card-title>
            <v-card-actions>
              <v-btn color="success" @click.stop="window++">Review</v-btn>
            </v-card-actions>
          </v-window-item>
          <v-window-item v-for="p in profiles" :key="p.playerProfileId">
            <v-card-text>
              <div>
                <v-icon class="mr-3" :color="p.phoneMatch ? 'success' : 'error'">fas fa-{{p.phoneMatch ? 'check' : 'times'}}-circle</v-icon>Same phone number
              </div>
              <div class="mt-2">
                <v-icon class="mr-3" :color="p.emailMatch ? 'success' : 'error'">fas fa-{{p.emailMatch ? 'check' : 'times'}}-circle</v-icon>Same email address
              </div>
              <search-result-item
                class="ml-n4"
                :player="p.player"
                :showId="true"
                :noEmit="true"
              ></search-result-item>
              <template v-if="p.events.length > 0">
                <v-btn
                  color="color3"
                  x-small
                  text
                  @click.stop="showEvents = !showEvents"
                >
                  {{showEvents ? 'hide' : 'show'}} events ({{p.events.length}})
                </v-btn>
                <v-expand-transition>
                  <player-events :tournaments="p.events" :listOnly="true" v-if="showEvents"></player-events>
                </v-expand-transition>
              </template>
            </v-card-text>
            <v-window v-model="actionWindow">
              <v-window-item :key="0">
                <v-card-title class="mb-0 pb-0">
                  Is this you?
                </v-card-title>
                <v-card-actions>
                  <v-btn color="success" @click.stop="claim = true">yes</v-btn>
                  <v-btn color="error" @click.stop="clean = true">No</v-btn>
                  <v-btn color="color3" x-small text @click.stop="window++">
                    skip
                  </v-btn>
                </v-card-actions>
              </v-window-item>
              <v-window-item :key="1">

              </v-window-item>
            </v-window>
            <vbl-confirm-dialog
              :ask="clean"
              :subText="`Clicking yes will remove your contact info from ${p.player.fullName}'s profile.`"
              @deny="clean = false"
              @confirm="cleanContact(p)"
              :loading="loading"
            ></vbl-confirm-dialog>
            <vbl-confirm-dialog
              :ask="claim"
              question="Would you like to claim this profile now?"
              @deny="claim = false"
              @confirm="gotoClaim(p)"
              :loading="loading"
            ></vbl-confirm-dialog>
          </v-window-item>
        </v-window>
      </v-card>
    </v-expand-transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
const SearchResultItem = () => import('@/components/Player/Search/SearchResultItem')
const PlayerEvents = () => import('@/components/Player/Events')

export default {
  data () {
    return {
      loading: false,
      profiles: [],
      window: 0,
      actionWindow: 0,
      showEvents: false,
      clean: false,
      claim: false
    }
  },
  computed: {
    ...mapGetters(['user']),
    someText () {
      return this.profiles.length === 1 ? 'a player profile that has' : `${this.profiles.length} player profiles that have`
    },
    userStatus () {
      // return -1
      if (!this.user) return 0 // 'No User'
      if (!this.user.hasVerifiedContact) return -1 // 'Need Verification'
      return 13
    }
  },
  methods: {
    fetchData () {
      this.loading = true
      this.$VBL.player.users.playerProfileCheck()
        .then(r => {
          this.profiles = r.data
          if (r.data.length) {
            this.window = 0
          }
        })
        .catch(e => console.log(e.response))
        .finally(() => { this.loading = false })
    },
    reset () {
      this.actionWindow = 0
      this.showEvents = false
    },
    cleanContact (p) {
      this.loading = true
      this.$VBL.player.users.playerProfileClean(p.playerProfileId)
        .then(r => {
          this.clean = false
          this.profiles = r.data
          if (r.data.length) {
            this.window = 0
          }
        })
        .catch(e => console.log(e.response))
        .finally(() => { this.loading = false })
    },
    gotoClaim (p) {
      this.$router.push(`/player/${p.playerProfileId}?claim=true`)
    }
  },
  watch: {
    window: 'reset',
    user: {
      deep: true,
      handler: 'fetchData'
    }
  },
  components: {
    SearchResultItem,
    PlayerEvents
  },
  mounted () {
    this.fetchData()
  }
}
</script>
